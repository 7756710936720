var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c("flow-viewer", {
        attrs: { operation: _vm.operation, taskDataId: _vm._taskDataId },
      })
    : _c("div", { staticClass: "main" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }